// src/components/TermsAndConditions.jsx

import React from 'react';
import { Collapse, Button } from 'react-bootstrap';


const TermsAndConditions = () => {
    const [open, setOpen] = React.useState(false);
    const [language, setLanguage] = React.useState('german'); // New state variable for the language

    const handleLanguageChange = () => {
        setLanguage(language === 'english' ? 'german' : 'english');
        setOpen(true);
    };

    return (
        <div id="team" style={[]} className="text-center">
            <div className="container">
                <div className="col-md-8 col-md-offset-2 section-title">
                    <h2>
                        {!open || language === 'english'
                            ? 'General Terms and Conditions (GTC) for MAiTE'
                            : 'Allgemeine Geschäftsbedingungen (AGB) für MAiTE'}
                    </h2>                 
                     <Button
                        className="btn btn-custom btn-sm"
                        onClick={handleLanguageChange}
                    >
                        {language === 'english' ? 'Show details in German' : 'Show details in English'}
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text">
                            {language === 'english' ? (
                                <>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Scope</h3>
                                    <p style={{ fontSize: '1.2em' }}>These General Terms and Conditions apply to all services provided by MAiTE. MAiTE offers an AI-based chatbot that serves as a life coach.</p>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Services</h3>
                                    <p style={{ fontSize: '1.2em' }}>MAiTE provides an AI chatbot that serves users as a friend, mentor, and companion, helping them deal with personal problems. The provider points out that the chatbot is not a substitute for professional medical or therapeutic help.</p>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Data Protection and Usage</h3>
                                    <ul style={{ fontSize: '1.2em', listStyleType: 'disc', textAlign: 'left' }}>
                                        <li>MAiTE collects user data such as email addresses, names, optional age and gender, as well as feedback data for the chat.</li>
                                        <li>Data collected in the chat is used for training and optimizing the MAiTE GPT and may be shared with OpenAI (to provide basic chat functionality).</li>
                                        <li>This data is used to inform users about new features or releases and to improve the product.</li>
                                        <li>The data is not shared with third parties.</li>
                                        <li>MAiTE is committed to complying with the General Data Protection Regulation (GDPR) and provides transparent information about data usage.</li>
                                        <li>The person responsible for data collection at MAiTE is Tim Friedrich, dataprotection@mateawake.com. The Data Protection Officer can be reached at dataprotection@mateawake.com.</li>
                                        <li>Data will be stored as long as necessary for the purpose of informing users or until consent is withdrawn, but no longer than 6 months.</li>
                                        <li>Users have the right to access, correct, delete their data, and to object to or restrict data processing. Right to complain to a supervisory authority in case of suspected misuse of data.</li>
                                    </ul>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>User Obligations and Rights</h3>
                                    <ul style={{ fontSize: '1.2em', listStyleType: 'disc', textAlign: 'left' }}>
                                        <li>Users are obliged not to use the service for illegal activities.</li>
                                        <li>Users have the right to withdraw their consent to data processing at any time.</li>
                                        <li>Users acknowledge that the recommendations of the chatbot do not replace professional advice.</li>
                                    </ul>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Disclaimer</h3>
                                    <p style={{ fontSize: '1.2em' }}>MAiTE is not liable for damages resulting from following advice or information provided by the chatbot. The chatbot is for information and entertainment purposes only.</p>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Changes to the Terms and Conditions</h3>
                                    <p style={{ fontSize: '1.2em' }}>MAiTE reserves the right to change these Terms and Conditions at any time. Changes will be communicated to users and will take effect upon their publication.</p>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Applicable Law and Jurisdiction</h3>
                                    <p style={{ fontSize: '1.2em' }}>These Terms and Conditions are subject to the law of the Federal Republic of Germany. The place of jurisdiction, as far as legally permissible, is the location of MAiTE in Baden-Württemberg.</p>
                                </>) : (<>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Geltungsbereich</h3>
                                    <p style={{ fontSize: '1.2em' }}>Diese Allgemeinen Geschäftsbedingungen gelten für alle Dienstleistungen, die von MAiTE bereitgestellt werden. MAiTE bietet einen KI-basierten Chatbot an, der als Life Coach dient.</p>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Dienstleistungen</h3>
                                    <p style={{ fontSize: '1.2em' }}>MAiTE stellt einen KI-Chatbot zur Verfügung, der Nutzern als Freund, Mentor und Begleiter dient und ihnen hilft, mit persönlichen Problemen umzugehen. Der Anbieter weist darauf hin, dass der Chatbot nicht als Ersatz für professionelle medizinische oder therapeutische Hilfe dient.</p>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Datenschutz und Datennutzung</h3>
                                    <ul style={{ fontSize: '1.2em', listStyleType: 'disc', textAlign: 'left' }}>
                                        <li>MAiTE erhebt Nutzerdaten wie E-Mail-Adressen, Namen, optional Alter und Geschlecht sowie Feedback-Daten zum Chat.</li>
                                        <li>Die im Chat erfassten Daten werden zum Training und zur Optimierung des MAiTE GPT verwendet und können an OpenAI (zur Ermöglichung der Chat-Funktionalität) weitergeleitet werden.</li>
                                        <li>Diese Daten werden verwendet, um Nutzer über neue Funktionen oder Veröffentlichungen zu informieren und das Produkt zu verbessern.</li>
                                        <li>Die Daten werden nicht an Dritte weitergegeben.</li>
                                        <li>MAiTE verpflichtet sich zur Einhaltung der Datenschutz-Grundverordnung (DSGVO) und stellt transparente Informationen zur Datennutzung bereit.</li>
                                        <li>Verantwortlich für die Datenerhebung ist Simon Schalong, dataprotection@mateawake.com. Der Datenschutzbeauftragte ist erreichbar unter dataprotection@mateawake.com.</li>
                                        <li>Daten werden so lange gespeichert, wie es für den Informationszweck notwendig ist oder bis zur Widerrufung der Einwilligung, jedoch nicht länger als 6 Monate.</li>
                                        <li>Nutzer haben das Recht auf Zugang, Berichtigung, Löschung ihrer Daten und auf Widerspruch gegen die Datenverarbeitung. Beschwerderecht bei einer Aufsichtsbehörde bei vermutetem Missbrauch der Daten.</li>
                                    </ul>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Nutzerpflichten und -rechte</h3>
                                    <ul style={{ fontSize: '1.2em', listStyleType: 'disc', textAlign: 'left' }}>
                                        <li>Nutzer verpflichten sich, den Dienst nicht für rechtswidrige Aktivitäten zu verwenden.</li>
                                        <li>Nutzer haben das Recht, ihre Einwilligung zur Datenverarbeitung jederzeit zu widerrufen.</li>
                                        <li>Nutzer erkennen an, dass die Empfehlungen des Chatbots nicht professionelle Beratung ersetzen.</li>
                                    </ul>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Haftungsausschluss</h3>
                                    <p style={{ fontSize: '1.2em' }}>MAiTE haftet nicht für Schäden, die aus der Befolgung von Ratschlägen oder Informationen resultieren, die durch den Chatbot bereitgestellt werden. Der Chatbot dient ausschließlich zu Informations- und Unterhaltungszwecken.</p>
                                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Änderungen der AGB</h3>
                                    <p style={{ fontSize: '1.2em' }}>MAiTE behält sich das Recht vor, diese AGB jederzeit zu ändern. Änderungen werden den Nutzern mitgeteilt und treten mit ihrer Veröffentlichung in Kraft.</p>
                                    <p style={{ fontSize: '1.2em' }}>Diese Allgemeinen Geschäftsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland. Der Gerichtsstand, soweit gesetzlich zulässig, ist der Standort von MAiTE in Baden-Württemberg.</p>
                                </>)}
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;

